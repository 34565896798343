<template>
  <div class="version-banner">
    <Message id="versionCheck" severity="success">
      <div class="message-container">
        <span>A new version of the app is available!</span>
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="reloadApp()"
        >
          Reload page
        </button>
      </div>
    </Message>
  </div>
</template>
<script lang="ts" setup>
  // Store
  // Properties
  // Computed properties
  // Hooks
  // Methods and Event handlers
  const reloadApp = () => {
    window.location.reload()
  }
</script>
<style scoped lang="scss">
  .version-banner {
    display: flex;
    justify-content: center;
    .p-message {
      border-radius: 0;
      outline-style: none;
      width: 65rem;
      padding: 1rem;
      background-color: $compile-banner-color;
      color: $white;
    }
    .message-container {
      display: flex;
      width: 60rem;
      justify-content: space-between;
      align-items: center;
      color: $white;
      & .btn-outline-primary {
        color: $compile-blue-dark;
        border-color: $compile-blue-dark;
        &:hover {
          color: $white;
        }
      }
    }
  }
</style>
