<template>
  <Message
    v-if="expiryDetails.show"
    severity="error"
    closable
    @close="closeExpiryBanner()"
  >
    Your access to McKesson Compile services will expire on
    <span>{{ expiryDetails.date }}. </span>
    <a class="ms-2" :href="renewURL" @click.prevent="navigateToRenew()"
      >Renew Now</a
    >
  </Message>
</template>
<script lang="ts" setup>
  // Runtime config composable to refer environment variables
  const config = useRuntimeConfig()

  // Store
  const appStore = useAppStore()

  // Computed properties
  const expiryDetails = computed(() => {
    const expiryDetails = { show: false, date: '' }
    if (!appStore.hasUserInfo()) {
      return expiryDetails
    }
    const expiryDate = new Date(appStore.user.expiry_date)
    // Show expiry banner if the expiry date is less than or equal to 60 days
    expiryDetails.show =
      dateDiffInDays(expiryDate) <= 60 && !appStore.isRenewBannerDismissed

    if (expiryDetails.show) {
      appStore.setIExpiryBannerVisible(true)
    }
    // Date formatting
    expiryDetails.date = HelperMethods.localDateFormat(expiryDate)

    return expiryDetails
  })

  const renewURL = computed(() => {
    return HelperMethods.urlWithRedirect(
      config.public.accountsURL + '/accounts/renew-access/',
    )
  })

  // Methods and Event handlers
  const dateDiffInDays = (expiryDate: Date) => {
    const today = new Date()
    // Converting time difference to days.
    return (expiryDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
  }

  const closeExpiryBanner = () => {
    appStore.setRenewDismissed(true)
  }
  const navigateToRenew = () => {
    window.open(renewURL.value, '_self')
  }
</script>
