<template>
  <div class="loader-dialog_overlay">
    <div class="loader-dialog_container">
      <div class="loader-dialog_icon"></div>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style lang="scss" scoped>
  @import '@/assets/scss/_color-palette.scss';
  .loader-dialog {
    &_overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: $compile-shadow-35;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }

    &_container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_body {
      padding: 1.5rem;
      padding-top: 0;
      border-radius: 8px;
      box-shadow: 0 10px 30px 0 $compile-shadow-4;
      background-color: $white;
    }
    &_icon {
      border: 6px solid $snow;
      border-top: 6px solid $dark-blue;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
